import React from 'react';
import { Nav, Navbar, Container} from 'react-bootstrap'
import '../../assets/CustomNavBar.css'


const logo = require('../../image/Atomo.gif');
//const logo = require('../../image/logo_empresa.png');
//{`${process.env.PUBLIC_URL}/About`} <Link className="hyperlink_custom" to="#nosotros">Nosotros</Link>
export const CustomNavBar = props => {
    return (
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" className="align-item-center nav_menu">
            <Container>
                <Navbar.Brand to={`${process.env.PUBLIC_URL}/`}>
                    <img src={logo} className="logo_empresa" alt='Control de Calidad S.A de C.V El Salvador'></img>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto parent fondo_menu" style={{width: "150px;"}}>
                        <a href="#inicio" className="hyperlink_custom">Inicio</a>
                        <a href="#nosotros" className='hyperlink_custom'>Nosotros</a>
                        <a href="#servicios" className="hyperlink_custom">Servicios</a>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            </Container>
        </Navbar>
    );
};

export default CustomNavBar;

/*
                    <Nav.Link href="/Contact">Contacto</Nav.Link> */