import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import '../../assets/style.css';
import contacto from '../../image/contacto.png'
 
export const Footer = props => {
    return (
        <footer id="footer" className="site-footer">
            <Container>
                <Row className="justify-content-center">
                    <Col className="social_footer" >
                        <Image src={contacto} variant='top' useMap='#mapa_imagen'></Image>
                            <map name="mapa_imagen">
                                <area target="_blank" alt="Instagram" title="Instagram" href="https://www.instagram.com/ccalidadsv/" coords="37,52,25" shape="circle" />
                                <area target="_blank" alt="Email" title="Email" href="mailto:atencion.cliente@ccalidadsv.com" coords="126,143,25" shape="circle" />
                                <area target="_blank" alt="Facebook" title="Facebook" href="https://www.facebook.com/Control-de-Calidad-107530733925383/" coords="248,112,25" shape="circle" />
                                <area target="_blank" alt="Twitter" title="Twitter" href="https://www.twitter.com/ccalidadsv" coords="140,291,25" shape="circle" />
                            </map>
                    </Col>
                </Row>
                <Row className="justify-content-center footer_row">
                    <Col className='footer3 text-center'>
                        <p className="copyright-text" ><strong>PBX:</strong> <a href="tel:50325227263">+503 2522-2817</a></p>
                    </Col>
                    <Col className='footer2 text-center'>
                        <p className="copyright-text">
                            <span className='copyright-text-bold'>Dirección:</span> Centro de Negocios STOfficenter, Colonia San Benito, calle La Mascota, #533, edificio 533, San Salvador.
                        </p>
                    </Col>   
                    <Col className='footer1 text-center'>
                        <p className='copyright-text'><strong>Copyright &copy;</strong></p>
                    </Col>
                </Row>
            </Container>
        </footer> 
    );
};


export default Footer;