import React from 'react';
import './App.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import CustomNavBar from './Components/CustomNavBar/CustomNavBar';
import { Footer } from './Components/Footer/Footer';
import Home from './Components/Home/Home';

function App() {
  return (    
    <BrowserRouter>
    <CustomNavBar />
    <div id="content-wrap" className='d-flex flex-column min-vh-100'>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}></Route>
      </Switch>
    </div>
    <Footer></Footer>
    </BrowserRouter>  
  );
 
  
}

export default App;

/*
            <Route exact path="/Contact" component={Contact}></Route> */




            /*
            
              <Router>
        <div> 
            <CustomNavBar />
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}></Route>
              <Route exact path={`${process.env.PUBLIC_URL}/About`} component={About}></Route>
              <Route exact path={`${process.env.PUBLIC_URL}/Services`} component={Services}></Route>
            <Footer></Footer>
        </div>
    </Router>
            */