import React from 'react';
import { Row, Col, Image, Card, Container } from 'react-bootstrap'
import micro_img from '../../image/micro.png';
import icono_responsa from '../../image/icono_resp.png';
import icono_calidad from '../../image/icono_calidad.png';
import icono_inn from '../../image/icono_inno.png';
import icono_exc from '../../image/icono_excel.png';
import estrellas from '../../image/estrellas.png';
import mujer from '../../image/Mujer.png';
import lineasM from '../../image/LinieasM.png';
import lineasV from '../../image/lineasV.png';
import hombre from '../../image/Hombre.png';
import primerProducto from '../../image/product1.png';
import segundoProducto from '../../image/product2.png';
import tercerProducto from '../../image/product3.png';
import cuartoProducto from '../../image/product4.png';
import quintoProducto from '../../image/product5.png';
import comiasRojas from '../../image/ComiasRojas.png';
import btnLlamarHoy from '../../image/btonPhone.png';
import ScrollButton from './../CustomNavBar/ScrollButton';
import '../../assets/style.css'

const Home = props => {
    return (
        <>
        <div className="jumbotron_custom_main jumbotron" id="inicio">
            <Container className='container_home'> 
                <Row className=' justify-content-center'>
                    <Image src={micro_img} rounded></Image>
                </Row>
                <Row className="justify-content-center">
                    <Col md={{ span: 6 }} className='texto_home'>
                                Como respuesta a las necesidades de la industria nacional e
                                internacional en la gestión de resultados óptimos en su sistema
                                de calidad, asi como la constante innovación de póliticas que garanticen
                                la trazabilidad en cada uno de sus procesos.

                    </Col>
                </Row>
            </Container>
        </div>
        <div className='jumbotron_custom_valores jumbotron'>
                <Container className='container_home'>
                    <Row className='justify-content-center dos_columnas'>
                        <Col md={3} className="cols_format">
                            <Card className='card_valores'>
                                <Card.Body>
                                    <Card.Img variant='top' src={icono_calidad} className='icons_Home dot_Home'>
                                    </Card.Img>
                                    <Card.Title className='card_titulos_valores'>
                                        Calidad
                                    </Card.Title>
                                    <Card.Text className='card_texto_valores'>
                                    Determinada por el conjunto de características inherentes, nuestro sistema de
                                    gestión nos permite administrar de manera eficiente todos los aspectos que
                                    engloban las necesidades de nuestros clientes
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='card_pies'>
                                    <Card.Img variant='botton' src={estrellas}></Card.Img>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col sm={3} className="cols_format">
                            <Card className='card_valores'>
                                <Card.Body>
                                    <Card.Img variant='top' src={icono_responsa} className='icons_Home dot_Home'>
                                    </Card.Img>
                                    <Card.Title className='card_titulos_valores'>
                                        Responsabilidad
                                    </Card.Title>
                                    <Card.Text className='card_texto_valores'>
                                    Comprendida en el análisis de los impactos que puedan presentan nuestras relaciones
                                    comerciales con clientes, proveedores y la sociedad misma en conjunto, al buscar generar
                                    un crecimiento y competitividad para brindar un modelo de Negocio que potencialice un
                                    desarrollo sostenible
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='card_pies'>
                                    <Card.Img variant='botton' src={estrellas}></Card.Img>
                                </Card.Footer>
                            </Card>

                        </Col>
                        <Col md={3} className="cols_format">
                            <Card className='card_valores'>
                                <Card.Body>
                                    <Card.Img variant='top' src={icono_inn} className='icons_Home dot_Home'>
                                    </Card.Img>
                                    <Card.Title className='card_titulos_valores'>
                                        Innovación
                                    </Card.Title>
                                    <Card.Text className='card_texto_valores'>
                                        Los cambios en los avances tecnológicos acorde a un mundo globalizado son uno
                                        de los principales componentes que nos mueven, por ellos surge nuestro
                                        compromiso de alcanzar el nivel competitivo demandado por nuestros clientes
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='card_pies'>
                                    <Card.Img variant='botton' src={estrellas}></Card.Img>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col md={3} className="cols_format">
                            <Card className='card_valores'>
                                <Card.Body>
                                    <Card.Img variant='top' src={icono_exc} className='icons_Home dot_Home'>
                                    </Card.Img>
                                    <Card.Title className='card_titulos_valores'>
                                        Excelencia
                                    </Card.Title>
                                    <Card.Text className='card_texto_valores'>
                                        Nuestro bien más preciado, el motor de nuestra entidad, por quien hacemos día a
                                        día nuestro mejor esfuerzo para cumplir con los más altos estándares de
                                        excelencia en nuestra empresa son nuestros clientes, por ello nace el compromiso
                                        de brindar lo mejor de nosotros para dar respuesta a sus necesidades, acorde al
                                        uso de sistemas de calidad que buscan mejorar todos los procesos y áreas dentro
                                        de nuestra organización
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='card_pies'>
                                    <Card.Img variant='botton' src={estrellas}></Card.Img>
                                </Card.Footer>
                            </Card>
                        </Col>
                        
                    </Row>
                    <hr className='valores'></hr>
                </Container>
            </div>
            <div className='jumbotron_custom_nosotros jumbotron'>
                <Container className='container_home'>
                    <Row className='rubik_bold nosotros_titulo' id="nosotros">
                        <ul className='lista_servicios'>
                            <li>Nosotros</li>
                        </ul> 
                    </Row>
                    <Row className='nosotros_row dos_columnas'>
                        <Col className='nosotros_cols'>
                            <Card className='card_nosotros_mision'>
                                <Card.Body>
                                    <Card.Title className='rubik_semi mision_titulo'>Misión</Card.Title>
                                    <Card.Text className='qk_sam mision_texto'>
                                        Nos comprometemos con nuestros clientes en ofrecer
                                        equipos, insumos y soporte técnico especializado, en la
                                        rama de control de calidad para las diferentes
                                        industrias, al contar con un catálogo de productos y
                                        servicios diversificados que permitan satisfacer las
                                        exigencias y requerimientos de nuestros clientes
                                    </Card.Text>
                                    <Card.Title>
                                        <Image src={lineasM} width={350}></Image>
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='mujer_estilo'>
                            <Image src={mujer} className='mujer_img'></Image>
                        </Col>
                        <Col className='nosotros_cols'>
                            <Card className='card_nosotros_vision'>
                                <Card.Body>
                                    <Card.Title className='vision_titulos rubik_semi'>Visión</Card.Title>
                                    <Card.Text className='qk_sam vision_texto'>
                                        Ser una empresa lider en la comercialización y
                                        distribución de equipos, insumos y soporte técnico
                                        especializado en la rama de control de calidad para las
                                        diferentes industrias, ofreciendo un catálogo
                                        diversificado de productos y servicios a precios
                                        competitivos, posicionando nuestra empresa, por
                                        medio de la fidelidad de nuestros clientes como un
                                        referente en el sector, con una imágen innovadora y
                                        diferenciada de nuestros competidores
                                    </Card.Text>
                                    <Card.Title>
                                        <Image src={lineasV} width={350}></Image>
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='cuadro_mujer'>
                           <div style={{display: 'none'}}>Control de Calidad de El Salvador</div> 
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='jumbotron_custom_servicios jumbotron'>
                <Container className='container_servicios'>
                    <Row className='justify-content-center row_servicios'>
                        <Col sm={12} className='fondo_col_servicios'>
                            <div align="center">
                                <a href='tel:50325227263' rol='button'>
                                    <Image src={btnLlamarHoy} className='llamar_ahora'></Image>
                                </a>
                            </div>
                            
                        </Col>
                    </Row>
                    <Row className='rubik_bold nosotros_titulo servicios_titulo' id="servicios">
                        <ul className='lista_servicios'>
                            <li>Productos y servicios</li>
                        </ul> 
                    </Row>
                    <Row className='servicios_row'>
                        <Col className='col_servicios'>
                            <Card className='card_servicios'>
                                <Card.Body>
                                    <Card.Text className='qk_sam card_text_servicios'>Nuestros asesores de ventas especialistas en el
                                       equipamiento de laboratorios, pondrán a su
                                       disposiciones las mejores tecnologías de
                                       acuerdo con las necesidades de sus proyectos
                                    </Card.Text>
                                    <div align='right' className='comas qk_sam'>
                                        <Image src={comiasRojas} height={70}></Image>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} className='hombre_estilo'>
                            <Image src={hombre} className='hombre_img'></Image>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Card className='card_servicios_areas'>
                            <Card.Body align='center'>
                                <Card.Text className='card_text_areas rubik_regular'>
                                    Instrumentos y equipamiento de laboratorio y procesos en las 
                                    siguientes áreas
                                </Card.Text>
                                
                            </Card.Body>
                        </Card>
                        
                    </Row>
                    <div align='center' className='fondo_productos'>
                        <Row className='justify-content-center'>
                            <Image src={primerProducto} className='img_prd1'></Image>
                        </Row>
                        <Row className='justify-content-center servicios_row_text'>
                            <Card className='card_lab'>
                                <Card.Body className='card_text_lab'>
                                    <Card.Title className='rubik_bold car_title_lab'>Laboratorio</Card.Title>                                    
                                    <Card.Text>
                                        <ul className='lista_servicios_puntos qk_sam'>
                                            <li>Aceites y lubricantes</li>
                                            <li>Agroindustria</li>
                                            <li>Alimentos</li>
                                            <li>Análisis de agua</li>
                                            <li>Asfaltos y construcción</li>
                                            <li>Avicultura</li>
                                            <li>Bebidas</li>
                                            <li>Cementos</li>
                                            <li>Cosméticos</li>
                                            <li>Generación Eléctrica</li>
                                            <li>Industria Textil</li>
                                            <li>Ingenios Azucareros</li>
                                            <li>Lácteos</li>
                                            <li>Petróleos</li>
                                            <li>Pinturas</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className='justify-content-center'>
                            <Image src={segundoProducto} className='img_prd1'></Image>
                        </Row>
                        <Row className='justify-content-center servicios_row_text'>
                            <Card className='card_lab2'>
                                <Card.Body className='card_text_lab2'>
                                    <Card.Title className='rubik_bold car_title_lab2'>Eléctrica</Card.Title>
                                    <Card.Text>
                                        <ul className='lista_servicios_puntos_columna qk_sam'>
                                            <li>Plantas de emergencia eléctrica</li>
                                            <li>Transferencias automáticas</li>
                                            <li>Reguladores de voltaje</li>
                                            <li>Transformadores eléctricos</li>
                                            <li>Motores eléctricos</li>
                                            <li>Bombas hidráulicas</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className='justify-content-center'>
                            <Image src={tercerProducto} className='img_prd1'></Image>
                        </Row>
                        <Row className='justify-content-center servicios_row_text'>
                            <Card className='card_lab2'>
                                <Card.Body className='card_text_lab2'>
                                    <Card.Title className='rubik_bold car_title_lab2'>Hidráulica</Card.Title>
                                    <Card.Text>
                                        <ul className='lista_servicios_puntos_columna qk_sam'>
                                            <li>Mantenimiento preventivo a pozos</li>
                                            <li>Bombas y motores sumergibles</li>
                                            <li>Arrancadores automáticas y controles automáticos</li>
                                            <li>Diseño e implementación de sistema de riegos</li>
                                            <li>Filtros de piscinas</li>
                                            <li>Bombas de periféricas</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className='justify-content-center'>
                            <Image src={cuartoProducto} className='img_prd1'></Image>
                        </Row>
                        <Row className='justify-content-center servicios_row_text'>
                            <Card className='card_lab3'>
                                <Card.Body className='card_text_lab3'>
                                    <Card.Title className='rubik_bold car_title_lab3'>Servicios de Ingeniería Civil</Card.Title>
                                    <Card.Text>
                                        <ul className='lista_servicios_puntos_columna qk_sam'>
                                            <li>Topografia: levantamiento topográficos y altimetría</li>
                                            <li>Construcción y diseño: elaboración de presupuestos, ejecución de obra civil, diseño de obra nueva con imágenes renderizadas, elaboración de planos</li>
                                            <li>Avalúos y trámites: Avalúos de inmuebles, tramitología diversa CNR</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className='justify-content-center'>
                            <Image src={quintoProducto} className='img_prd1'></Image>
                        </Row>
                        <Row className='justify-content-center servicios_row_text servicios_row_text_final'>
                            <Card className='card_lab4'>
                                <Card.Body className='card_text_lab4'>
                                    <Card.Title className='rubik_bold car_title_lab4'>Ingeniería en Soporte Técnico Especializado</Card.Title>
                                    <Card.Text className='lista_servicios_puntos_columna qk_sam'>
                                        Contamos con personal altamente calificado con experiencia comprobable,
                                        formación técnica basada según protocolos de los fabricantes
                                    </Card.Text>
                                    <Card.Text className='lista_servicios_puntos_columna qk_sam'>
                                        Herramientas y estándares trazables con su respectiva certificación nos
                                        respaldan, nuestro firme objetivo es garantizar el perfecto funcionamiento
                                        de los instrumentos y equipos mediante los siguientes procesos:
                                    </Card.Text>
                                    <Card.Text>
                                        <ul className='lista_servicios_puntos_columna qk_sam align-items-start justify-content-between'>
                                            <li>Mantenimiento preventivo y correctivo a equipos especializados de ingeniería</li>
                                            <li>Calibración de equipos</li>
                                            <li>Caracterización de instrumentos</li>
                                            <li>Verificación de instrumentos</li>
                                            <li>Diagnóstico</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                    </div>
                </Container>
            </div>
            <ScrollButton />
        </>
    );
}
export default Home;

/*

   <Row>
                    <Col md={4}></Col>
                    <Col md={8}>
                        <p className="vals_information">
                            Como respuesta a las necesidades de la industria nacional e internacional
                            en la gestión de resultados óptimos en su sistema de calidad, así como
                            la constante innovación de políticas que garanticen la trazabilidad 
                            en cada uno de sus procesos
                        </p>
                    </Col>
                </Row>
                */